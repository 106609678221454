.br-2px {
  border-radius: 2px !important;
}

.br-2px {
  border-radius: 2px !important;
}

.br-4px {
  border-radius: 4px !important;
}

.br-6px {
  border-radius: 6px !important;
}

.br-8px {
  border-radius: 8px !important;
}

.br-10px {
  border-radius: 10px !important;
}

.br-12px {
  border-radius: 12px !important;
}

.br-14px {
  border-radius: 14px !important;
}

.br-16px {
  border-radius: 16px !important;
}

.br-17px {
  border-radius: 17px !important;
}

.br-18px {
  border-radius: 18px !important;
}

.br-20px {
  border-radius: 20px !important;
}

.br-30px {
  border-radius: 30px !important;
}

@import url("./border-radius.css");
@import url("./colors.css");
@import url("./flex-box.css");
@import url("./font-size.css");
@import url("./font-weight.css");
@import url("./spacing.css");
@import url("./width-height.css");

.tooltip_new {
  position: relative;
  display: inline-block;
  cursor: default;
  /* border-bottom: 1px dotted black; */
}

.tooltip_new .tooltiptext_new {
  visibility: hidden;
  width: 320px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: left;
  box-shadow: 1px 1px 7px gray;
  border-radius: 6px;
  padding: 5px 10px;

  position: absolute;
  z-index: 1;
  top: 100%;
  right: 30%;
  margin-left: -60px;
}

.tooltip_new:hover .tooltiptext_new {
  visibility: visible;
}

.frame {
  overflow-y: auto;
  border: 1px solid black;
  height: 500px;
  line-height: 1em;
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
  height: 11px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.label-font {
  color: black;
  font-size: 45;
  font-weight: 500;
}

.shadow-box {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
}

.input-label {
  font-weight: 600 !important;
  /* font-family: "Epilogue", sans-serif; */
  font-family: "Andada Pro", serif;
}

.create-new-button {
  padding: 10px 20px;
  border: none;
  background-color: #ed7117;
  border-radius: 25px;
  font-weight: bold;
  color: white;
}

.table-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.table-header-title {
  font-size: 30px;
  /* font-family: "Epilogue", sans-serif; */
  /* font-family: "Andada Pro", serif; */
  font-weight: 400;
}

.font-Courier {
  font-family: "Courier New", Courier, monospace;
}

/* body {
  font-family: "BioRhyme", serif !important;
} */

.modal-content {
  border-radius: 20px !important;
}

.delete-modal .modal-content {
  background-color: white;
  /* background-image: linear-gradient(to right, #202020, gray); */
}

.rfid-card-view-modal .modal-content {
  /* background-image: linear-gradient(to right, #f2994a, #f2c94c); */
  background-image: url(https://ak.picdn.net/shutterstock/videos/15390865/thumb/1.jpg);
  height: 300px;
  padding: 20px 20px;
}

.rfid-card-datepicker {
  position: absolute !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-disabled {
  color: #d7d7d7 !important;
}

@media screen and (max-width: 1199px) {
  img.bike-image-operator-window {
    width: 40% !important;
  }
}

@media screen and (max-width: 1199px) and (min-width: 500px) {
  img.bike-image-operator-window {
    width: 25% !important;
  }
}

@media screen and (max-width: 1522px) {
  .student-filter-section {
    justify-content: center !important;
  }
}

.bike-image-operator-window {
  width: 60% !important;
}

/* Css for Bottom Nav Bar */
body {
  margin: 0 0 55px 0;
}

.table.table th,
table.table td {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  font-family: "Andada Pro", serif;
}

table.table td {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  color: black;
  font-weight: 400;
  /* font-family: "Epilogue", sans-serif; */
}

.dataTable_scrollBody {
  min-height: 150px !important;
}

.course-edit-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-right: 5px;
  color: white;
  cursor: pointer;
}

.course-edit-icon:hover {
  background-color: white;
  color: black;
}

.student-filter-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.filter-icon {
  cursor: pointer;
  z-index: 10;
  filter: invert(69%) sepia(51%) saturate(7143%) hue-rotate(356deg)
    brightness(99%) contrast(87%);
}

.student-null-image {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  font-size: 60px;
  color: white;
  font-weight: 900;
  /* font-family: "Epilogue", sans-serif; */
  font-family: "Andada Pro", serif;
  box-shadow: 1px 1px 7px #acacac;
}

.student-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.student-fixed-details-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.content-model-style {
  max-width: 98% !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.content-model-content-style {
  height: 95vh;
}

.c_DFF4FB {
  color: #dff4fb !important;
}

.c_AFE2F4 {
  color: #afe2f4 !important;
}

.c_7ACEED {
  color: #7aceed !important;
}

.c_0CACE3 {
  color: #0cace3 !important;
}

.c_0092D1 {
  color: #0092d1 !important;
}

.c_00508B {
  color: #00508b !important;
}

.c_white {
  color: #ffffff !important;
}

.c_B00020 {
  color: #b00020 !important;
}

.c_4CAF50 {
  color: #4caf50 !important;
}

.c_FFA726 {
  color: #ffa726 !important;
}

.c_BF360C {
  color: #bf360c !important;
}

.c_ed7117 {
  color: #ed7117;
}

.c_F5F5F5 {
  color: #f5f5f5;
}

.c_616161 {
  color: #616161;
}

.c_black-100p {
  color: rgba(0, 0, 0, 1) !important;
}

.c_black-87p {
  color: rgba(0, 0, 0, 0.87) !important;
}

.c_black-68p {
  color: rgba(0, 0, 0, 0.68) !important;
}

.c_black-60p {
  color: rgba(0, 0, 0, 0.6) !important;
}

.c_black-38p {
  color: rgba(0, 0, 0, 0.38) !important;
}

.c_black-32p {
  color: rgba(0, 0, 0, 0.32) !important;
}

.c_black-12p {
  color: rgba(0, 0, 0, 0.12);
}

.c_black-8p {
  color: rgba(0, 0, 0, 0.08);
}

/* Background Color */

.bg-DFF4FB {
  background-color: #dff4fb !important;
}

.bg-AFE2F4 .bg-primary-100 {
  background-color: #afe2f4 !important;
}

.bg-7ACEED .bg-primary-200 {
  background-color: #7aceed !important;
}

.bg-0CACE3 .bg-primary-400 .bg-primary {
  background-color: #0cace3 !important;
}

.bg-0092D1 {
  background-color: #0092d1 !important;
}

.bg-primary-variant {
  background-color: #00508b !important;
}

.bg-white .bg-FFFFFF {
  background-color: #ffffff !important;
}

.bg-ed7117 {
  background-color: #ed7117 !important;
}

.bg-B00020 {
  background-color: #b00020 !important;
}

.bg-rating-green .bg-4CAF50 {
  background-color: #4caf50 !important;
}

.bg-rating-orange .bg-FFA726 {
  background-color: #ffa726 !important;
}

.bg-rating-error .bg-BF360C {
  background-color: #bf360c !important;
}

.bg-F5F5F5 {
  background-color: #f5f5f5;
}

.bg-616161 {
  background-color: #616161;
}

.bg-black .bg-black-100p {
  background-color: rgba(0, 0, 0, 1) !important;
}

.bg-black-87p {
  background-color: rgba(0, 0, 0, 0.87) !important;
}

.bg-black-60p {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.bg-black-8p {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.bg-black-disabled .bg-black-38p {
  background-color: rgba(0, 0, 0, 0.38);
}

.bg-black-32p {
  background-color: rgba(0, 0, 0, 0.32);
}

.bg-black-12p {
  background-color: rgba(0, 0, 0, 0.12);
}

.bg-black-8p {
  background-color: rgba(0, 0, 0, 0.08);
}

/* Border Colors */
.border-c-DFF4FB .border-c-primary-50 {
  border-color: #dff4fb !important;
}

.border-c-AFE2F4 .border-c-primary-100 {
  border-color: #afe2f4 !important;
}

.border-c-7ACEED .border-c-primary-200 {
  border-color: #7aceed !important;
}

.border-c-0CACE3 {
  border-color: #0cace3 !important;
}

.border-c-0092D1 .border-c-primary-600 {
  border-color: #0092d1 !important;
}

.border-c-00508B .border-c-primary-900 .border-c-primary-variant {
  border-color: #00508b !important;
}

.border-c-white .border-c-FFFFFF {
  border-color: #ffffff !important;
}

.border-c-error .border-c-B00020 {
  border-color: #b00020 !important;
}

.border-c-rating-green .border-c-4CAF50 {
  border-color: #4caf50 !important;
}

.border-c-rating-orange .border-c-FFA726 {
  border-color: #ffa726 !important;
}

.border-c-rating-error .border-c-BF360C {
  border-color: #bf360c !important;
}

.border-c-F5F5F5 {
  border-color: #f5f5f5;
}

.border-c-616161 {
  border-color: #616161;
}

.border-c-black .border-c-black-100p {
  border-color: rgba(0, 0, 0, 1);
}

.border-c-black-87p {
  border-color: rgba(0, 0, 0, 0.87);
}

.border-c-black-60p {
  border-color: rgba(0, 0, 0, 0.6);
}

.border-c-black-disabled .border-c-black-38p {
  border-color: rgba(0, 0, 0, 0.38);
}

.border-c-black-32p {
  border-color: rgba(0, 0, 0, 0.32);
}

.border-c-black-12p {
  border-color: rgba(0, 0, 0, 0.12);
}

.border-c-black-8p {
  border-color: rgba(0, 0, 0, 0.08);
}

.border-1px-black-60p {
  border: 1px solid rgba(0, 0, 0, 0.6);
}

.border-1px-black-12p {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.pt-1px {
  padding-top: 1px !important;
}

.pt-2px {
  padding-top: 2px !important;
}

.pt-3px {
  padding-top: 3px !important;
}

.pt-4px {
  padding-top: 4px !important;
}

.pt-5px {
  padding-top: 5px !important;
}

.pt-6px {
  padding-top: 6px !important;
}

.pt-7px {
  padding-top: 7px !important;
}
.pt-8px {
  padding-top: 8px !important;
}

.pt-9px {
  padding-top: 9px !important;
}

.pt-10px {
  padding-top: 10px !important;
}

.pt-11px {
  padding-top: 11px !important;
}

.pt-12px {
  padding-top: 12px !important;
}

.pt-13px {
  padding-top: 13px !important;
}

.pt-14px {
  padding-top: 14px !important;
}

.pt-15px {
  padding-top: 15px !important;
}

.pt-16px {
  padding-top: 16px !important;
}

.pt-17px {
  padding-top: 17px !important;
}

.pt-18px {
  padding-top: 18px !important;
}

.pt-19px {
  padding-top: 19px !important;
}

.pt-20px {
  padding-top: 20px !important;
}

.pt-21px {
  padding-top: 21px !important;
}

.pt-22px {
  padding-top: 22px !important;
}

.pt-23px {
  padding-top: 23px !important;
}

.pt-24px {
  padding-top: 24px !important;
}

.pt-25px {
  padding-top: 25px !important;
}

.pt-26px {
  padding-top: 26px !important;
}

.pt-27px {
  padding-top: 27px !important;
}

.pt-28px {
  padding-top: 28px !important;
}

.pt-29px {
  padding-top: 29px !important;
}

.pt-30px {
  padding-top: 30px !important;
}

.pt-31px {
  padding-top: 31px !important;
}

.pt-32px {
  padding-top: 32px !important;
}

.pt-33px {
  padding-top: 33px !important;
}

.pt-34px {
  padding-top: 34px !important;
}

.pt-35px {
  padding-top: 35px !important;
}

.pt-36px {
  padding-top: 36px !important;
}

.pt-37px {
  padding-top: 37px !important;
}

.pt-38px {
  padding-top: 38px !important;
}

.pt-39px {
  padding-top: 39px !important;
}

.pt-40px {
  padding-top: 40px !important;
}

.pt-41px {
  padding-top: 41px !important;
}

.pt-42px {
  padding-top: 42px !important;
}

.pt-43px {
  padding-top: 43px !important;
}

.pt-44px {
  padding-top: 44px !important;
}

.pt-45px {
  padding-top: 45px !important;
}

.pt-46px {
  padding-top: 46px !important;
}

.pt-47px {
  padding-top: 47px !important;
}

.pt-48px {
  padding-top: 48px !important;
}

.pt-49px {
  padding-top: 49px !important;
}

.pt-50px {
  padding-top: 50px !important;
}

.pb-1px {
  padding-bottom: 1px !important;
}

.pb-2px {
  padding-bottom: 2px !important;
}

.pb-3px {
  padding-bottom: 3px !important;
}

.pb-4px {
  padding-bottom: 4px !important;
}

.pb-5px {
  padding-bottom: 5px !important;
}

.pb-6px {
  padding-bottom: 6px !important;
}

.pb-7px {
  padding-bottom: 7px !important;
}
.pb-8px {
  padding-bottom: 8px !important;
}

.pb-9px {
  padding-bottom: 9px !important;
}

.pb-10px {
  padding-bottom: 10px !important;
}

.pb-11px {
  padding-bottom: 11px !important;
}

.pb-12px {
  padding-bottom: 12px !important;
}

.pb-13px {
  padding-bottom: 13px !important;
}

.pb-14px {
  padding-bottom: 14px !important;
}

.pb-15px {
  padding-bottom: 15px !important;
}

.pb-16px {
  padding-bottom: 16px !important;
}

.pb-17px {
  padding-bottom: 17px !important;
}

.pb-18px {
  padding-bottom: 18px !important;
}

.pb-19px {
  padding-bottom: 19px !important;
}

.pb-20px {
  padding-bottom: 20px !important;
}

.pb-21px {
  padding-bottom: 21px !important;
}

.pb-22px {
  padding-bottom: 22px !important;
}

.pb-23px {
  padding-bottom: 23px !important;
}

.pb-24px {
  padding-bottom: 24px !important;
}

.pb-25px {
  padding-bottom: 25px !important;
}

.pb-26px {
  padding-bottom: 26px !important;
}

.pb-27px {
  padding-bottom: 27px !important;
}

.pb-28px {
  padding-bottom: 28px !important;
}

.pb-29px {
  padding-bottom: 29px !important;
}

.pb-30px {
  padding-bottom: 30px !important;
}

.pb-31px {
  padding-bottom: 31px !important;
}

.pb-32px {
  padding-bottom: 32px !important;
}

.pb-33px {
  padding-bottom: 33px !important;
}

.pb-34px {
  padding-bottom: 34px !important;
}

.pb-35px {
  padding-bottom: 35px !important;
}

.pb-36px {
  padding-bottom: 36px !important;
}

.pb-37px {
  padding-bottom: 37px !important;
}

.pb-38px {
  padding-bottom: 38px !important;
}

.pb-39px {
  padding-bottom: 39px !important;
}

.pb-40px {
  padding-bottom: 40px !important;
}

.pb-41px {
  padding-bottom: 41px !important;
}

.pb-42px {
  padding-bottom: 42px !important;
}

.pb-43px {
  padding-bottom: 43px !important;
}

.pb-44px {
  padding-bottom: 44px !important;
}

.pb-45px {
  padding-bottom: 45px !important;
}

.pb-46px {
  padding-bottom: 46px !important;
}

.pb-47px {
  padding-bottom: 47px !important;
}

.pb-48px {
  padding-bottom: 48px !important;
}

.pb-49px {
  padding-bottom: 49px !important;
}

.pb-50px {
  padding-bottom: 50px !important;
}

.pl-1px {
  padding-left: 1px !important;
}

.pl-2px {
  padding-left: 2px !important;
}

.pl-3px {
  padding-left: 3px !important;
}

.pl-4px {
  padding-left: 4px !important;
}

.pl-5px {
  padding-left: 5px !important;
}

.pl-6px {
  padding-left: 6px !important;
}

.pl-7px {
  padding-left: 7px !important;
}
.pl-8px {
  padding-left: 8px !important;
}

.pl-9px {
  padding-left: 9px !important;
}

.pl-10px {
  padding-left: 10px !important;
}

.pl-11px {
  padding-left: 11px !important;
}

.pl-12px {
  padding-left: 12px !important;
}

.pl-13px {
  padding-left: 13px !important;
}

.pl-14px {
  padding-left: 14px !important;
}

.pl-15px {
  padding-left: 15px !important;
}

.pl-16px {
  padding-left: 16px !important;
}

.pl-17px {
  padding-left: 17px !important;
}

.pl-18px {
  padding-left: 18px !important;
}

.pl-19px {
  padding-left: 19px !important;
}

.pl-20px {
  padding-left: 20px !important;
}

.pl-21px {
  padding-left: 21px !important;
}

.pl-22px {
  padding-left: 22px !important;
}

.pl-23px {
  padding-left: 23px !important;
}

.pl-24px {
  padding-left: 24px !important;
}

.pl-25px {
  padding-left: 25px !important;
}

.pl-26px {
  padding-left: 26px !important;
}

.pl-27px {
  padding-left: 27px !important;
}

.pl-28px {
  padding-left: 28px !important;
}

.pl-29px {
  padding-left: 29px !important;
}

.pl-30px {
  padding-left: 30px !important;
}

.pl-31px {
  padding-left: 31px !important;
}

.pl-32px {
  padding-left: 32px !important;
}

.pl-33px {
  padding-left: 33px !important;
}

.pl-34px {
  padding-left: 34px !important;
}

.pl-35px {
  padding-left: 35px !important;
}

.pl-36px {
  padding-left: 36px !important;
}

.pl-37px {
  padding-left: 37px !important;
}

.pl-38px {
  padding-left: 38px !important;
}

.pl-39px {
  padding-left: 39px !important;
}

.pl-40px {
  padding-left: 40px !important;
}

.pl-41px {
  padding-left: 41px !important;
}

.pl-42px {
  padding-left: 42px !important;
}

.pl-43px {
  padding-left: 43px !important;
}

.pl-44px {
  padding-left: 44px !important;
}

.pl-45px {
  padding-left: 45px !important;
}

.pl-46px {
  padding-left: 46px !important;
}

.pl-47px {
  padding-left: 47px !important;
}

.pl-48px {
  padding-left: 48px !important;
}

.pl-49px {
  padding-left: 49px !important;
}

.pl-50px {
  padding-left: 50px !important;
}

.pr-1px {
  padding-right: 1px !important;
}

.pr-2px {
  padding-right: 2px !important;
}

.pr-3px {
  padding-right: 3px !important;
}

.pr-4px {
  padding-right: 4px !important;
}

.pr-5px {
  padding-right: 5px !important;
}

.pr-6px {
  padding-right: 6px !important;
}

.pr-7px {
  padding-right: 7px !important;
}
.pr-8px {
  padding-right: 8px !important;
}

.pr-9px {
  padding-right: 9px !important;
}

.pr-10px {
  padding-right: 10px !important;
}

.pr-11px {
  padding-right: 11px !important;
}

.pr-12px {
  padding-right: 12px !important;
}

.pr-13px {
  padding-right: 13px !important;
}

.pr-14px {
  padding-right: 14px !important;
}

.pr-15px {
  padding-right: 15px !important;
}

.pr-16px {
  padding-right: 16px !important;
}

.pr-17px {
  padding-right: 17px !important;
}

.pr-18px {
  padding-right: 18px !important;
}

.pr-19px {
  padding-right: 19px !important;
}

.pr-20px {
  padding-right: 20px !important;
}

.pr-21px {
  padding-right: 21px !important;
}

.pr-22px {
  padding-right: 22px !important;
}

.pr-23px {
  padding-right: 23px !important;
}

.pr-24px {
  padding-right: 24px !important;
}

.pr-25px {
  padding-right: 25px !important;
}

.pr-26px {
  padding-right: 26px !important;
}

.pr-27px {
  padding-right: 27px !important;
}

.pr-28px {
  padding-right: 28px !important;
}

.pr-29px {
  padding-right: 29px !important;
}

.pr-30px {
  padding-right: 30px !important;
}

.pr-31px {
  padding-right: 31px !important;
}

.pr-32px {
  padding-right: 32px !important;
}

.pr-33px {
  padding-right: 33px !important;
}

.pr-34px {
  padding-right: 34px !important;
}

.pr-35px {
  padding-right: 35px !important;
}

.pr-36px {
  padding-right: 36px !important;
}

.pr-37px {
  padding-right: 37px !important;
}

.pr-38px {
  padding-right: 38px !important;
}

.pr-39px {
  padding-right: 39px !important;
}

.pr-40px {
  padding-right: 40px !important;
}

.pr-41px {
  padding-right: 41px !important;
}

.pr-42px {
  padding-right: 42px !important;
}

.pr-43px {
  padding-right: 43px !important;
}

.pr-44px {
  padding-right: 44px !important;
}

.pr-45px {
  padding-right: 45px !important;
}

.pr-46px {
  padding-right: 46px !important;
}

.pr-47px {
  padding-right: 47px !important;
}

.pr-48px {
  padding-right: 48px !important;
}

.pr-49px {
  padding-right: 49px !important;
}

.pr-50px {
  padding-right: 50px !important;
}

/* Negative Padding */

.pt-n1px {
  padding-top: -1px !important;
}

.pt-n2px {
  padding-top: -2px !important;
}

.pt-n3px {
  padding-top: -3px !important;
}

.pt-n4px {
  padding-top: -4px !important;
}

.pt-n5px {
  padding-top: -5px !important;
}

.pt-n6px {
  padding-top: -6px !important;
}

.pt-n7px {
  padding-top: -7px !important;
}
.pt-n8px {
  padding-top: -8px !important;
}

.pt-n9px {
  padding-top: -9px !important;
}

.pt-n10px {
  padding-top: -10px !important;
}

.pt-n11px {
  padding-top: -11px !important;
}

.pt-n12px {
  padding-top: -12px !important;
}

.pt-n13px {
  padding-top: -13px !important;
}

.pt-n14px {
  padding-top: -14px !important;
}

.pt-n15px {
  padding-top: -15px !important;
}

.pt-n16px {
  padding-top: -16px !important;
}

.pt-n17px {
  padding-top: -17px !important;
}

.pt-n18px {
  padding-top: -18px !important;
}

.pt-n19px {
  padding-top: -19px !important;
}

.pt-n20px {
  padding-top: -20px !important;
}

.pt-n21px {
  padding-top: -21px !important;
}

.pt-n22px {
  padding-top: -22px !important;
}

.pt-n23px {
  padding-top: -23px !important;
}

.pt-n24px {
  padding-top: -24px !important;
}

.pt-n25px {
  padding-top: -25px !important;
}

.pt-n26px {
  padding-top: -26px !important;
}

.pt-n27px {
  padding-top: -27px !important;
}

.pt-n28px {
  padding-top: -28px !important;
}

.pt-n29px {
  padding-top: -29px !important;
}

.pt-n30px {
  padding-top: -30px !important;
}

.pt-n31px {
  padding-top: -31px !important;
}

.pt-n32px {
  padding-top: -32px !important;
}

.pt-n33px {
  padding-top: -33px !important;
}

.pt-n34px {
  padding-top: -34px !important;
}

.pt-n35px {
  padding-top: -35px !important;
}

.pt-n36px {
  padding-top: -36px !important;
}

.pt-n37px {
  padding-top: -37px !important;
}

.pt-n38px {
  padding-top: -38px !important;
}

.pt-n39px {
  padding-top: -39px !important;
}

.pt-n40px {
  padding-top: -40px !important;
}

.pt-n41px {
  padding-top: -41px !important;
}

.pt-n42px {
  padding-top: -42px !important;
}

.pt-n43px {
  padding-top: -43px !important;
}

.pt-n44px {
  padding-top: -44px !important;
}

.pt-n45px {
  padding-top: -45px !important;
}

.pt-n46px {
  padding-top: -46px !important;
}

.pt-n47px {
  padding-top: -47px !important;
}

.pt-n48px {
  padding-top: -48px !important;
}

.pt-n49px {
  padding-top: -49px !important;
}

.pt-n50px {
  padding-top: -50px !important;
}

.pb-n1px {
  padding-bottom: -1px !important;
}

.pb-n2px {
  padding-bottom: -2px !important;
}

.pb-n3px {
  padding-bottom: -3px !important;
}

.pb-n4px {
  padding-bottom: -4px !important;
}

.pb-n5px {
  padding-bottom: -5px !important;
}

.pb-n6px {
  padding-bottom: -6px !important;
}

.pb-n7px {
  padding-bottom: -7px !important;
}
.pb-n8px {
  padding-bottom: -8px !important;
}

.pb-n9px {
  padding-bottom: -9px !important;
}

.pb-n10px {
  padding-bottom: -10px !important;
}

.pb-n11px {
  padding-bottom: -11px !important;
}

.pb-n12px {
  padding-bottom: -12px !important;
}

.pb-n13px {
  padding-bottom: -13px !important;
}

.pb-n14px {
  padding-bottom: -14px !important;
}

.pb-n15px {
  padding-bottom: -15px !important;
}

.pb-n16px {
  padding-bottom: -16px !important;
}

.pb-n17px {
  padding-bottom: -17px !important;
}

.pb-n18px {
  padding-bottom: -18px !important;
}

.pb-n19px {
  padding-bottom: -19px !important;
}

.pb-n20px {
  padding-bottom: -20px !important;
}

.pb-n21px {
  padding-bottom: -21px !important;
}

.pb-n22px {
  padding-bottom: -22px !important;
}

.pb-n23px {
  padding-bottom: -23px !important;
}

.pb-n24px {
  padding-bottom: -24px !important;
}

.pb-n25px {
  padding-bottom: -25px !important;
}

.pb-n26px {
  padding-bottom: -26px !important;
}

.pb-n27px {
  padding-bottom: -27px !important;
}

.pb-n28px {
  padding-bottom: -28px !important;
}

.pb-n29px {
  padding-bottom: -29px !important;
}

.pb-n30px {
  padding-bottom: -30px !important;
}

.pb-n31px {
  padding-bottom: -31px !important;
}

.pb-n32px {
  padding-bottom: -32px !important;
}

.pb-n33px {
  padding-bottom: -33px !important;
}

.pb-n34px {
  padding-bottom: -34px !important;
}

.pb-n35px {
  padding-bottom: -35px !important;
}

.pb-n36px {
  padding-bottom: -36px !important;
}

.pb-n37px {
  padding-bottom: -37px !important;
}

.pb-n38px {
  padding-bottom: -38px !important;
}

.pb-n39px {
  padding-bottom: -39px !important;
}

.pb-n40px {
  padding-bottom: -40px !important;
}

.pb-n41px {
  padding-bottom: -41px !important;
}

.pb-n42px {
  padding-bottom: -42px !important;
}

.pb-n43px {
  padding-bottom: -43px !important;
}

.pb-n44px {
  padding-bottom: -44px !important;
}

.pb-n45px {
  padding-bottom: -45px !important;
}

.pb-n46px {
  padding-bottom: -46px !important;
}

.pb-n47px {
  padding-bottom: -47px !important;
}

.pb-n48px {
  padding-bottom: -48px !important;
}

.pb-n49px {
  padding-bottom: -49px !important;
}

.pb-n50px {
  padding-bottom: -50px !important;
}

.pl-n1px {
  padding-left: -1px !important;
}

.pl-n2px {
  padding-left: -2px !important;
}

.pl-n3px {
  padding-left: -3px !important;
}

.pl-n4px {
  padding-left: -4px !important;
}

.pl-n5px {
  padding-left: -5px !important;
}

.pl-n6px {
  padding-left: -6px !important;
}

.pl-n7px {
  padding-left: -7px !important;
}
.pl-n8px {
  padding-left: -8px !important;
}

.pl-n9px {
  padding-left: -9px !important;
}

.pl-n10px {
  padding-left: -10px !important;
}

.pl-n11px {
  padding-left: -11px !important;
}

.pl-n12px {
  padding-left: -12px !important;
}

.pl-n13px {
  padding-left: -13px !important;
}

.pl-n14px {
  padding-left: -14px !important;
}

.pl-n15px {
  padding-left: -15px !important;
}

.pl-n16px {
  padding-left: -16px !important;
}

.pl-n17px {
  padding-left: -17px !important;
}

.pl-n18px {
  padding-left: -18px !important;
}

.pl-n19px {
  padding-left: -19px !important;
}

.pl-n20px {
  padding-left: -20px !important;
}

.pl-n21px {
  padding-left: -21px !important;
}

.pl-n22px {
  padding-left: -22px !important;
}

.pl-n23px {
  padding-left: -23px !important;
}

.pl-n24px {
  padding-left: -24px !important;
}

.pl-n25px {
  padding-left: -25px !important;
}

.pl-n26px {
  padding-left: -26px !important;
}

.pl-n27px {
  padding-left: -27px !important;
}

.pl-n28px {
  padding-left: -28px !important;
}

.pl-n29px {
  padding-left: -29px !important;
}

.pl-n30px {
  padding-left: -30px !important;
}

.pl-n31px {
  padding-left: -31px !important;
}

.pl-n32px {
  padding-left: -32px !important;
}

.pl-n33px {
  padding-left: -33px !important;
}

.pl-n34px {
  padding-left: -34px !important;
}

.pl-n35px {
  padding-left: -35px !important;
}

.pl-n36px {
  padding-left: -36px !important;
}

.pl-n37px {
  padding-left: -37px !important;
}

.pl-n38px {
  padding-left: -38px !important;
}

.pl-n39px {
  padding-left: -39px !important;
}

.pl-n40px {
  padding-left: -40px !important;
}

.pl-n41px {
  padding-left: -41px !important;
}

.pl-n42px {
  padding-left: -42px !important;
}

.pl-n43px {
  padding-left: -43px !important;
}

.pl-n44px {
  padding-left: -44px !important;
}

.pl-n45px {
  padding-left: -45px !important;
}

.pl-n46px {
  padding-left: -46px !important;
}

.pl-n47px {
  padding-left: -47px !important;
}

.pl-n48px {
  padding-left: -48px !important;
}

.pl-n49px {
  padding-left: -49px !important;
}

.pl-n50px {
  padding-left: -50px !important;
}

.pr-n1px {
  padding-right: -1px !important;
}

.pr-n2px {
  padding-right: -2px !important;
}

.pr-n3px {
  padding-right: -3px !important;
}

.pr-n4px {
  padding-right: -4px !important;
}

.pr-n5px {
  padding-right: -5px !important;
}

.pr-n6px {
  padding-right: -6px !important;
}

.pr-n7px {
  padding-right: -7px !important;
}
.pr-n8px {
  padding-right: -8px !important;
}

.pr-n9px {
  padding-right: -9px !important;
}

.pr-n10px {
  padding-right: -10px !important;
}

.pr-n11px {
  padding-right: -11px !important;
}

.pr-n12px {
  padding-right: -12px !important;
}

.pr-n13px {
  padding-right: -13px !important;
}

.pr-n14px {
  padding-right: -14px !important;
}

.pr-n15px {
  padding-right: -15px !important;
}

.pr-n16px {
  padding-right: -16px !important;
}

.pr-n17px {
  padding-right: -17px !important;
}

.pr-n18px {
  padding-right: -18px !important;
}

.pr-n19px {
  padding-right: -19px !important;
}

.pr-n20px {
  padding-right: -20px !important;
}

.pr-n21px {
  padding-right: -21px !important;
}

.pr-n22px {
  padding-right: -22px !important;
}

.pr-n23px {
  padding-right: -23px !important;
}

.pr-n24px {
  padding-right: -24px !important;
}

.pr-n25px {
  padding-right: -25px !important;
}

.pr-n26px {
  padding-right: -26px !important;
}

.pr-n27px {
  padding-right: -27px !important;
}

.pr-n28px {
  padding-right: -28px !important;
}

.pr-n29px {
  padding-right: -29px !important;
}

.pr-n30px {
  padding-right: -30px !important;
}

.pr-n31px {
  padding-right: -31px !important;
}

.pr-n32px {
  padding-right: -32px !important;
}

.pr-n33px {
  padding-right: -33px !important;
}

.pr-n34px {
  padding-right: -34px !important;
}

.pr-n35px {
  padding-right: -35px !important;
}

.pr-n36px {
  padding-right: -36px !important;
}

.pr-n37px {
  padding-right: -37px !important;
}

.pr-n38px {
  padding-right: -38px !important;
}

.pr-n39px {
  padding-right: -39px !important;
}

.pr-n40px {
  padding-right: -40px !important;
}

.pr-n41px {
  padding-right: -41px !important;
}

.pr-n42px {
  padding-right: -42px !important;
}

.pr-n43px {
  padding-right: -43px !important;
}

.pr-n44px {
  padding-right: -44px !important;
}

.pr-n45px {
  padding-right: -45px !important;
}

.pr-n46px {
  padding-right: -46px !important;
}

.pr-n47px {
  padding-right: -47px !important;
}

.pr-n48px {
  padding-right: -48px !important;
}

.pr-n49px {
  padding-right: -49px !important;
}

.pr-n50px {
  padding-right: -50px !important;
}

.px-1px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.px-2px {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.px-3px {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.px-4px {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.px-6px {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.px-8px {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.px-10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px-12px {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.px-14px {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.px-16px {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.px-18px {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.px-20px {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-22px {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.px-24px {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.px-26px {
  padding-left: 26px !important;
  padding-right: 26px !important;
}

.px-30px {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.px-32px {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.py-1px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.py-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.py-3px {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.py-4px {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-6px {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.py-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-12px {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-14px {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.py-16px {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-18px {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.py-20px {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-22px {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.py-24px {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-26px {
  padding-top: 26px !important;
  padding-bottom: 26px !important;
}

.py-30px {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-32px {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

/* Margin-Styles */

.mt-1px {
  margin-top: 1px !important;
}

.mt-2px {
  margin-top: 2px !important;
}

.mt-3px {
  margin-top: 3px !important;
}

.mt-4px {
  margin-top: 4px !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mt-6px {
  margin-top: 6px !important;
}

.mt-7px {
  margin-top: 7px !important;
}
.mt-8px {
  margin-top: 8px !important;
}

.mt-9px {
  margin-top: 9px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-11px {
  margin-top: 11px !important;
}

.mt-12px {
  margin-top: 12px !important;
}

.mt-13px {
  margin-top: 13px !important;
}

.mt-14px {
  margin-top: 14px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.mt-16px {
  margin-top: 16px !important;
}

.mt-17px {
  margin-top: 17px !important;
}

.mt-18px {
  margin-top: 18px !important;
}

.mt-19px {
  margin-top: 19px !important;
}

.mt-20px {
  margin-top: 20px !important;
}

.mt-21px {
  margin-top: 21px !important;
}

.mt-22px {
  margin-top: 22px !important;
}

.mt-23px {
  margin-top: 23px !important;
}

.mt-24px {
  margin-top: 24px !important;
}

.mt-25px {
  margin-top: 25px !important;
}

.mt-26px {
  margin-top: 26px !important;
}

.mt-27px {
  margin-top: 27px !important;
}

.mt-28px {
  margin-top: 28px !important;
}

.mt-29px {
  margin-top: 29px !important;
}

.mt-30px {
  margin-top: 30px !important;
}

.mt-31px {
  margin-top: 31px !important;
}

.mt-32px {
  margin-top: 32px !important;
}

.mt-33px {
  margin-top: 33px !important;
}

.mt-34px {
  margin-top: 34px !important;
}

.mt-35px {
  margin-top: 35px !important;
}

.mt-36px {
  margin-top: 36px !important;
}

.mt-37px {
  margin-top: 37px !important;
}

.mt-38px {
  margin-top: 38px !important;
}

.mt-39px {
  margin-top: 39px !important;
}

.mt-40px {
  margin-top: 40px !important;
}

.mt-41px {
  margin-top: 41px !important;
}

.mt-42px {
  margin-top: 42px !important;
}

.mt-43px {
  margin-top: 43px !important;
}

.mt-44px {
  margin-top: 44px !important;
}

.mt-45px {
  margin-top: 45px !important;
}

.mt-46px {
  margin-top: 46px !important;
}

.mt-47px {
  margin-top: 47px !important;
}

.mt-48px {
  margin-top: 48px !important;
}

.mt-49px {
  margin-top: 49px !important;
}

.mt-50px {
  margin-top: 50px !important;
}

.mb-1px {
  margin-bottom: 1px !important;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.mb-3px {
  margin-bottom: 3px !important;
}

.mb-4px {
  margin-bottom: 4px !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mb-6px {
  margin-bottom: 6px !important;
}

.mb-7px {
  margin-bottom: 7px !important;
}
.mb-8px {
  margin-bottom: 8px !important;
}

.mb-9px {
  margin-bottom: 9px !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.mb-11px {
  margin-bottom: 11px !important;
}

.mb-12px {
  margin-bottom: 12px !important;
}

.mb-13px {
  margin-bottom: 13px !important;
}

.mb-14px {
  margin-bottom: 14px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.mb-16px {
  margin-bottom: 16px !important;
}

.mb-17px {
  margin-bottom: 17px !important;
}

.mb-18px {
  margin-bottom: 18px !important;
}

.mb-19px {
  margin-bottom: 19px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.mb-21px {
  margin-bottom: 21px !important;
}

.mb-22px {
  margin-bottom: 22px !important;
}

.mb-23px {
  margin-bottom: 23px !important;
}

.mb-24px {
  margin-bottom: 24px !important;
}

.mb-25px {
  margin-bottom: 25px !important;
}

.mb-26px {
  margin-bottom: 26px !important;
}

.mb-27px {
  margin-bottom: 27px !important;
}

.mb-28px {
  margin-bottom: 28px !important;
}

.mb-29px {
  margin-bottom: 29px !important;
}

.mb-30px {
  margin-bottom: 30px !important;
}

.mb-31px {
  margin-bottom: 31px !important;
}

.mb-32px {
  margin-bottom: 32px !important;
}

.mb-33px {
  margin-bottom: 33px !important;
}

.mb-34px {
  margin-bottom: 34px !important;
}

.mb-35px {
  margin-bottom: 35px !important;
}

.mb-36px {
  margin-bottom: 36px !important;
}

.mb-37px {
  margin-bottom: 37px !important;
}

.mb-38px {
  margin-bottom: 38px !important;
}

.mb-39px {
  margin-bottom: 39px !important;
}

.mb-40px {
  margin-bottom: 40px !important;
}

.mb-41px {
  margin-bottom: 41px !important;
}

.mb-42px {
  margin-bottom: 42px !important;
}

.mb-43px {
  margin-bottom: 43px !important;
}

.mb-44px {
  margin-bottom: 44px !important;
}

.mb-45px {
  margin-bottom: 45px !important;
}

.mb-46px {
  margin-bottom: 46px !important;
}

.mb-47px {
  margin-bottom: 47px !important;
}

.mb-48px {
  margin-bottom: 48px !important;
}

.mb-49px {
  margin-bottom: 49px !important;
}

.mb-50px {
  margin-bottom: 50px !important;
}

.ml-1px {
  margin-left: 1px !important;
}

.ml-2px {
  margin-left: 2px !important;
}

.ml-3px {
  margin-left: 3px !important;
}

.ml-4px {
  margin-left: 4px !important;
}

.ml-5px {
  margin-left: 5px !important;
}

.ml-6px {
  margin-left: 6px !important;
}

.ml-7px {
  margin-left: 7px !important;
}
.ml-8px {
  margin-left: 8px !important;
}

.ml-9px {
  margin-left: 9px !important;
}

.ml-10px {
  margin-left: 10px !important;
}

.ml-11px {
  margin-left: 11px !important;
}

.ml-12px {
  margin-left: 12px !important;
}

.ml-13px {
  margin-left: 13px !important;
}

.ml-14px {
  margin-left: 14px !important;
}

.ml-15px {
  margin-left: 15px !important;
}

.ml-16px {
  margin-left: 16px !important;
}

.ml-17px {
  margin-left: 17px !important;
}

.ml-18px {
  margin-left: 18px !important;
}

.ml-19px {
  margin-left: 19px !important;
}

.ml-20px {
  margin-left: 20px !important;
}

.ml-21px {
  margin-left: 21px !important;
}

.ml-22px {
  margin-left: 22px !important;
}

.ml-23px {
  margin-left: 23px !important;
}

.ml-24px {
  margin-left: 24px !important;
}

.ml-25px {
  margin-left: 25px !important;
}

.ml-26px {
  margin-left: 26px !important;
}

.ml-27px {
  margin-left: 27px !important;
}

.ml-28px {
  margin-left: 28px !important;
}

.ml-29px {
  margin-left: 29px !important;
}

.ml-30px {
  margin-left: 30px !important;
}

.ml-31px {
  margin-left: 31px !important;
}

.ml-32px {
  margin-left: 32px !important;
}

.ml-33px {
  margin-left: 33px !important;
}

.ml-34px {
  margin-left: 34px !important;
}

.ml-35px {
  margin-left: 35px !important;
}

.ml-36px {
  margin-left: 36px !important;
}

.ml-37px {
  margin-left: 37px !important;
}

.ml-38px {
  margin-left: 38px !important;
}

.ml-39px {
  margin-left: 39px !important;
}

.ml-40px {
  margin-left: 40px !important;
}

.ml-41px {
  margin-left: 41px !important;
}

.ml-42px {
  margin-left: 42px !important;
}

.ml-43px {
  margin-left: 43px !important;
}

.ml-44px {
  margin-left: 44px !important;
}

.ml-45px {
  margin-left: 45px !important;
}

.ml-46px {
  margin-left: 46px !important;
}

.ml-47px {
  margin-left: 47px !important;
}

.ml-48px {
  margin-left: 48px !important;
}

.ml-49px {
  margin-left: 49px !important;
}

.ml-50px {
  margin-left: 50px !important;
}

.mr-1px {
  margin-right: 1px !important;
}

.mr-2px {
  margin-right: 2px !important;
}

.mr-3px {
  margin-right: 3px !important;
}

.mr-4px {
  margin-right: 4px !important;
}

.mr-5px {
  margin-right: 5px !important;
}

.mr-6px {
  margin-right: 6px !important;
}

.mr-7px {
  margin-right: 7px !important;
}
.mr-8px {
  margin-right: 8px !important;
}

.mr-9px {
  margin-right: 9px !important;
}

.mr-10px {
  margin-right: 10px !important;
}

.mr-11px {
  margin-right: 11px !important;
}

.mr-12px {
  margin-right: 12px !important;
}

.mr-13px {
  margin-right: 13px !important;
}

.mr-14px {
  margin-right: 14px !important;
}

.mr-15px {
  margin-right: 15px !important;
}

.mr-16px {
  margin-right: 16px !important;
}

.mr-17px {
  margin-right: 17px !important;
}

.mr-18px {
  margin-right: 18px !important;
}

.mr-19px {
  margin-right: 19px !important;
}

.mr-20px {
  margin-right: 20px !important;
}

.mr-21px {
  margin-right: 21px !important;
}

.mr-22px {
  margin-right: 22px !important;
}

.mr-23px {
  margin-right: 23px !important;
}

.mr-24px {
  margin-right: 24px !important;
}

.mr-25px {
  margin-right: 25px !important;
}

.mr-26px {
  margin-right: 26px !important;
}

.mr-27px {
  margin-right: 27px !important;
}

.mr-28px {
  margin-right: 28px !important;
}

.mr-29px {
  margin-right: 29px !important;
}

.mr-30px {
  margin-right: 30px !important;
}

.mr-31px {
  margin-right: 31px !important;
}

.mr-32px {
  margin-right: 32px !important;
}

.mr-33px {
  margin-right: 33px !important;
}

.mr-34px {
  margin-right: 34px !important;
}

.mr-35px {
  margin-right: 35px !important;
}

.mr-36px {
  margin-right: 36px !important;
}

.mr-37px {
  margin-right: 37px !important;
}

.mr-38px {
  margin-right: 38px !important;
}

.mr-39px {
  margin-right: 39px !important;
}

.mr-40px {
  margin-right: 40px !important;
}

.mr-41px {
  margin-right: 41px !important;
}

.mr-42px {
  margin-right: 42px !important;
}

.mr-43px {
  margin-right: 43px !important;
}

.mr-44px {
  margin-right: 44px !important;
}

.mr-45px {
  margin-right: 45px !important;
}

.mr-46px {
  margin-right: 46px !important;
}

.mr-47px {
  margin-right: 47px !important;
}

.mr-48px {
  margin-right: 48px !important;
}

.mr-49px {
  margin-right: 49px !important;
}

.mr-50px {
  margin-right: 50px !important;
}

/* Negative Padding */

.mt-n1px {
  margin-top: -1px !important;
}

.mt-n2px {
  margin-top: -2px !important;
}

.mt-n3px {
  margin-top: -3px !important;
}

.mt-n4px {
  margin-top: -4px !important;
}

.mt-n5px {
  margin-top: -5px !important;
}

.mt-n6px {
  margin-top: -6px !important;
}

.mt-n7px {
  margin-top: -7px !important;
}
.mt-n8px {
  margin-top: -8px !important;
}

.mt-n9px {
  margin-top: -9px !important;
}

.mt-n10px {
  margin-top: -10px !important;
}

.mt-n11px {
  margin-top: -11px !important;
}

.mt-n12px {
  margin-top: -12px !important;
}

.mt-n13px {
  margin-top: -13px !important;
}

.mt-n14px {
  margin-top: -14px !important;
}

.mt-n15px {
  margin-top: -15px !important;
}

.mt-n16px {
  margin-top: -16px !important;
}

.mt-n17px {
  margin-top: -17px !important;
}

.mt-n18px {
  margin-top: -18px !important;
}

.mt-n19px {
  margin-top: -19px !important;
}

.mt-n20px {
  margin-top: -20px !important;
}

.mt-n21px {
  margin-top: -21px !important;
}

.mt-n22px {
  margin-top: -22px !important;
}

.mt-n23px {
  margin-top: -23px !important;
}

.mt-n24px {
  margin-top: -24px !important;
}

.mt-n25px {
  margin-top: -25px !important;
}

.mt-n26px {
  margin-top: -26px !important;
}

.mt-n27px {
  margin-top: -27px !important;
}

.mt-n28px {
  margin-top: -28px !important;
}

.mt-n29px {
  margin-top: -29px !important;
}

.mt-n30px {
  margin-top: -30px !important;
}

.mt-n31px {
  margin-top: -31px !important;
}

.mt-n32px {
  margin-top: -32px !important;
}

.mt-n33px {
  margin-top: -33px !important;
}

.mt-n34px {
  margin-top: -34px !important;
}

.mt-n35px {
  margin-top: -35px !important;
}

.mt-n36px {
  margin-top: -36px !important;
}

.mt-n37px {
  margin-top: -37px !important;
}

.mt-n38px {
  margin-top: -38px !important;
}

.mt-n39px {
  margin-top: -39px !important;
}

.mt-n40px {
  margin-top: -40px !important;
}

.mt-n41px {
  margin-top: -41px !important;
}

.mt-n42px {
  margin-top: -42px !important;
}

.mt-n43px {
  margin-top: -43px !important;
}

.mt-n44px {
  margin-top: -44px !important;
}

.mt-n45px {
  margin-top: -45px !important;
}

.mt-n46px {
  margin-top: -46px !important;
}

.mt-n47px {
  margin-top: -47px !important;
}

.mt-n48px {
  margin-top: -48px !important;
}

.mt-n49px {
  margin-top: -49px !important;
}

.mt-n50px {
  margin-top: -50px !important;
}

.mt-n70px {
  margin-top: -70px !important;
}

.mb-n1px {
  margin-bottom: -1px !important;
}

.mb-n2px {
  margin-bottom: -2px !important;
}

.mb-n3px {
  margin-bottom: -3px !important;
}

.mb-n4px {
  margin-bottom: -4px !important;
}

.mb-n5px {
  margin-bottom: -5px !important;
}

.mb-n6px {
  margin-bottom: -6px !important;
}

.mb-n7px {
  margin-bottom: -7px !important;
}
.mb-n8px {
  margin-bottom: -8px !important;
}

.mb-n9px {
  margin-bottom: -9px !important;
}

.mb-n10px {
  margin-bottom: -10px !important;
}

.mb-n11px {
  margin-bottom: -11px !important;
}

.mb-n12px {
  margin-bottom: -12px !important;
}

.mb-n13px {
  margin-bottom: -13px !important;
}

.mb-n14px {
  margin-bottom: -14px !important;
}

.mb-n15px {
  margin-bottom: -15px !important;
}

.mb-n16px {
  margin-bottom: -16px !important;
}

.mb-n17px {
  margin-bottom: -17px !important;
}

.mb-n18px {
  margin-bottom: -18px !important;
}

.mb-n19px {
  margin-bottom: -19px !important;
}

.mb-n20px {
  margin-bottom: -20px !important;
}

.mb-n21px {
  margin-bottom: -21px !important;
}

.mb-n22px {
  margin-bottom: -22px !important;
}

.mb-n23px {
  margin-bottom: -23px !important;
}

.mb-n24px {
  margin-bottom: -24px !important;
}

.mb-n25px {
  margin-bottom: -25px !important;
}

.mb-n26px {
  margin-bottom: -26px !important;
}

.mb-n27px {
  margin-bottom: -27px !important;
}

.mb-n28px {
  margin-bottom: -28px !important;
}

.mb-n29px {
  margin-bottom: -29px !important;
}

.mb-n30px {
  margin-bottom: -30px !important;
}

.mb-n31px {
  margin-bottom: -31px !important;
}

.mb-n32px {
  margin-bottom: -32px !important;
}

.mb-n33px {
  margin-bottom: -33px !important;
}

.mb-n34px {
  margin-bottom: -34px !important;
}

.mb-n35px {
  margin-bottom: -35px !important;
}

.mb-n36px {
  margin-bottom: -36px !important;
}

.mb-n37px {
  margin-bottom: -37px !important;
}

.mb-n38px {
  margin-bottom: -38px !important;
}

.mb-n39px {
  margin-bottom: -39px !important;
}

.mb-n40px {
  margin-bottom: -40px !important;
}

.mb-n41px {
  margin-bottom: -41px !important;
}

.mb-n42px {
  margin-bottom: -42px !important;
}

.mb-n43px {
  margin-bottom: -43px !important;
}

.mb-n44px {
  margin-bottom: -44px !important;
}

.mb-n45px {
  margin-bottom: -45px !important;
}

.mb-n46px {
  margin-bottom: -46px !important;
}

.mb-n47px {
  margin-bottom: -47px !important;
}

.mb-n48px {
  margin-bottom: -48px !important;
}

.mb-n49px {
  margin-bottom: -49px !important;
}

.mb-n50px {
  margin-bottom: -50px !important;
}

.ml-n1px {
  margin-left: -1px !important;
}

.ml-n2px {
  margin-left: -2px !important;
}

.ml-n3px {
  margin-left: -3px !important;
}

.ml-n4px {
  margin-left: -4px !important;
}

.ml-n5px {
  margin-left: -5px !important;
}

.ml-n6px {
  margin-left: -6px !important;
}

.ml-n7px {
  margin-left: -7px !important;
}

.ml-n8px {
  margin-left: -8px !important;
}

.ml-n9px {
  margin-left: -9px !important;
}

.ml-n10px {
  margin-left: -10px !important;
}

.ml-n11px {
  margin-left: -11px !important;
}

.ml-n12px {
  margin-left: -12px !important;
}

.ml-n13px {
  margin-left: -13px !important;
}

.ml-n14px {
  margin-left: -14px !important;
}

.ml-n15px {
  margin-left: -15px !important;
}

.ml-n16px {
  margin-left: -16px !important;
}

.ml-n17px {
  margin-left: -17px !important;
}

.ml-n18px {
  margin-left: -18px !important;
}

.ml-n19px {
  margin-left: -19px !important;
}

.ml-n20px {
  margin-left: -20px !important;
}

.ml-n21px {
  margin-left: -21px !important;
}

.ml-n22px {
  margin-left: -22px !important;
}

.ml-n23px {
  margin-left: -23px !important;
}

.ml-n24px {
  margin-left: -24px !important;
}

.ml-n25px {
  margin-left: -25px !important;
}

.ml-n26px {
  margin-left: -26px !important;
}

.ml-n27px {
  margin-left: -27px !important;
}

.ml-n28px {
  margin-left: -28px !important;
}

.ml-n29px {
  margin-left: -29px !important;
}

.ml-n30px {
  margin-left: -30px !important;
}

.ml-n31px {
  margin-left: -31px !important;
}

.ml-n32px {
  margin-left: -32px !important;
}

.ml-n33px {
  margin-left: -33px !important;
}

.ml-n34px {
  margin-left: -34px !important;
}

.ml-n35px {
  margin-left: -35px !important;
}

.ml-n36px {
  margin-left: -36px !important;
}

.ml-n37px {
  margin-left: -37px !important;
}

.ml-n38px {
  margin-left: -38px !important;
}

.ml-n39px {
  margin-left: -39px !important;
}

.ml-n40px {
  margin-left: -40px !important;
}

.ml-n41px {
  margin-left: -41px !important;
}

.ml-n42px {
  margin-left: -42px !important;
}

.ml-n43px {
  margin-left: -43px !important;
}

.ml-n44px {
  margin-left: -44px !important;
}

.ml-n45px {
  margin-left: -45px !important;
}

.ml-n46px {
  margin-left: -46px !important;
}

.ml-n47px {
  margin-left: -47px !important;
}

.ml-n48px {
  margin-left: -48px !important;
}

.ml-n49px {
  margin-left: -49px !important;
}

.ml-n50px {
  margin-left: -50px !important;
}

.mr-n1px {
  margin-right: -1px !important;
}

.mr-n2px {
  margin-right: -2px !important;
}

.mr-n3px {
  margin-right: -3px !important;
}

.mr-n4px {
  margin-right: -4px !important;
}

.mr-n5px {
  margin-right: -5px !important;
}

.mr-n6px {
  margin-right: -6px !important;
}

.mr-n7px {
  margin-right: -7px !important;
}
.mr-n8px {
  margin-right: -8px !important;
}

.mr-n9px {
  margin-right: -9px !important;
}

.mr-n10px {
  margin-right: -10px !important;
}

.mr-n11px {
  margin-right: -11px !important;
}

.mr-n12px {
  margin-right: -12px !important;
}

.mr-n13px {
  margin-right: -13px !important;
}

.mr-n14px {
  margin-right: -14px !important;
}

.mr-n15px {
  margin-right: -15px !important;
}

.mr-n16px {
  margin-right: -16px !important;
}

.mr-n17px {
  margin-right: -17px !important;
}

.mr-n18px {
  margin-right: -18px !important;
}

.mr-n19px {
  margin-right: -19px !important;
}

.mr-n20px {
  margin-right: -20px !important;
}

.mr-n21px {
  margin-right: -21px !important;
}

.mr-n22px {
  margin-right: -22px !important;
}

.mr-n23px {
  margin-right: -23px !important;
}

.mr-n24px {
  margin-right: -24px !important;
}

.mr-n25px {
  margin-right: -25px !important;
}

.mr-n26px {
  margin-right: -26px !important;
}

.mr-n27px {
  margin-right: -27px !important;
}

.mr-n28px {
  margin-right: -28px !important;
}

.mr-n29px {
  margin-right: -29px !important;
}

.mr-n30px {
  margin-right: -30px !important;
}

.mr-n31px {
  margin-right: -31px !important;
}

.mr-n32px {
  margin-right: -32px !important;
}

.mr-n33px {
  margin-right: -33px !important;
}

.mr-n34px {
  margin-right: -34px !important;
}

.mr-n35px {
  margin-right: -35px !important;
}

.mr-n36px {
  margin-right: -36px !important;
}

.mr-n37px {
  margin-right: -37px !important;
}

.mr-n38px {
  margin-right: -38px !important;
}

.mr-n39px {
  margin-right: -39px !important;
}

.mr-n40px {
  margin-right: -40px !important;
}

.mr-n41px {
  margin-right: -41px !important;
}

.mr-n42px {
  margin-right: -42px !important;
}

.mr-n43px {
  margin-right: -43px !important;
}

.mr-n44px {
  margin-right: -44px !important;
}

.mr-n45px {
  margin-right: -45px !important;
}

.mr-n46px {
  margin-right: -46px !important;
}

.mr-n47px {
  margin-right: -47px !important;
}

.mr-n48px {
  margin-right: -48px !important;
}

.mr-n49px {
  margin-right: -49px !important;
}

.mr-n50px {
  margin-right: -50px !important;
}

.mx-1px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.mx-2px {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.mx-3px {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.mx-4px {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.mx-6px {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.mx-8px {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mx-10px {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mx-12px {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.mx-14px {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.mx-16px {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.mx-18px {
  margin-left: 18px !important;
  margin-right: 18px !important;
}

.mx-20px {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mx-22px {
  margin-left: 22px !important;
  margin-right: 22px !important;
}

.mx-24px {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.mx-26px {
  margin-left: 26px !important;
  margin-right: 26px !important;
}

.mx-30px {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mx-32px {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.mx-43px {
  margin-left: 43px !important;
  margin-right: 43px !important;
}

.my-1px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.my-2px {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.my-3px {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.my-4px {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-6px {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.my-8px {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-10px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-12px {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.my-14px {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.my-16px {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-18px {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.my-20px {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-22px {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.my-24px {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-26px {
  margin-top: 26px !important;
  margin-bottom: 26px !important;
}

.my-30px {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my-32px {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}
